<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  // 不需要导入或注册LoginPage组件，因为你已经在路由中定义了它
};
</script>
  
<style>
  
</style>
