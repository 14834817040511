import Dexie from 'dexie';

const db = new Dexie('LocalStorageDatabase');
db.version(1).stores({
  contractChangeDetails: 'rowID, contractName',
  prodValueDetails:'rowID', 
  BudgetDeductionDetails:'rowID', 
  FundDetails:'rowID', 
  FundSchedule:'rowID', 
  PrintSchedule:'rowID',
  table_V: 'id',
  // ... 其他表定义
});

export default db;