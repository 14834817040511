// src/router/index.js

import { createRouter, createWebHashHistory } from 'vue-router';
const LoginPage = () => import('../components/LoginPage.vue');
const home = () => import('../components/Home.vue');
const PdfPreview = () => import('../components/utils/PdfPreview.vue');

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/home',
    name: 'Home',
    component: home
  },
  {
    path: '/pdf-preview',
    name: 'PdfPreview',
    component: PdfPreview,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
