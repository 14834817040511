import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store"; // 引入store
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import axios from 'axios';
import ElementPlus from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "element-plus/dist/index.css";

// Use icons in libs
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

axios.interceptors.request.use(function (config) {
  // 从localStorage或其他存储中获取token
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    // console.log(config.headers.Authorization)
  }
  return config;
}, function (error) {
  // 请求错误时做些事
  return Promise.reject(error);
});

// 设置 axios 响应拦截器
axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response) {
    const status = error.response.status;
    const message = error.response.data.message;
    
    if (status === 401 || status === 403) {
      this.$meaasge({
        message: message,
        type: 'error',
        duration: 1500,
      });
      localStorage.removeItem('token'); // 清除本地存储中的 token
      router.push('/'); // 重定向到登录页面
    }
  }
  return Promise.reject(error);
});

const app = createApp(App);

// 注册 ElementPlus 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 使用 ElementPlus UI 库
app.use(ElementPlus, {
  locale: zhCn,
});

// 使用 Vue Router
app.use(router);

// 使用 Vuex
app.use(store); // 添加这行来使用store

let loadingInstance = null;

store.subscribe((mutation, state) => {
  if (mutation.type === 'updateLoadingText') {
    if (loadingInstance) {
      loadingInstance.close(); // 如果已有实例，先关闭
    }
    loadingInstance = app.config.globalProperties.$loading({
      lock: true,
      text: state.loadingText,
      background: 'rgba(0, 0, 0, 0.7)',
    });
  }
});

  store
    .dispatch("checkAndUpdateData", { updateLoadingText: true })
    .then(() => {
      if (loadingInstance) loadingInstance.close();
      app.mount("#app");
    })
    .catch((error) => {
      console.error("Error during initial data fetch:", error);
      if (loadingInstance) loadingInstance.close();
      app.mount("#app"); // 即使有错误，也应该挂载应用
    });