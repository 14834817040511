// src/store/actions.js

import axios from "axios";
import apiConfig from "@/config/apiConfig.js";
import db from "@/dexie/database.js";

export default {
  performAction({ commit }, { filtermore, filter }) {
    commit("addHistory", { filtermore, filter });
    commit("setIsUndoing", false); // 重置 isUndoing 状态
  },
  undoAction({ commit, state }) {
    commit("undo");
    return state.history;
  },
  saveState({ commit, state }, { filtermore, filter }) {
    if (!state.isUndoing) {
      commit("addHistory", { filtermore, filter });
      console.log(state.history);
    }
    commit("setIsUndoing", false);
  },
  clearHistory({ commit }) {
    commit("clearHistory");
  },
  setUndoingtrue({ commit }) {
    commit("setIsUndoing", true);
  },
  setUndoingfalse({ commit }) {
    commit("setIsUndoing", false);
  },
  turnworking({ commit }) {
    commit("setworking");
  },
  resetworking({ commit }) {
    commit("resetworking");
  },
  resetIsnew({ commit }) {
    commit("isnewfalse");
  },
  // updateDevelopmentProjects({ commit }, projects) {
  //   commit("SET_DEVELOPMENT_PROJECTS", projects);
  // },
  addDevelopmentProject({ commit }, project) {
    commit("ADD_DEVELOPMENT_PROJECT", project);
  },
  removeDevelopmentProject({ commit }, projectId) {
    commit("REMOVE_DEVELOPMENT_PROJECT", projectId);
  },
  editDevelopmentProject({ commit }, updatedProject) {
    commit("EDIT_DEVELOPMENT_PROJECT", updatedProject);
  },
  // 修改页面状态
  updatePageState({ commit }, { activeTab, currentrow }) {
    commit('SET_PAGE_STATE', { activeTab, currentrow });
  },
  // 重置页面状态为默认值
  resetPageState({ commit }) {
    commit('RESET_PAGE_STATE');
  },
  resetV({ commit }) {
    localStorage.setItem("projectV", "0");
    localStorage.setItem("productV", "0");
    localStorage.setItem("partyBV", "0");
    localStorage.setItem("contractV", "0");
    localStorage.setItem("contractChangeDetailsV", "0");
    localStorage.setItem("prodValueDetailsV", "0");
    localStorage.setItem("BudgetDeductionDetailsV", "0");
    localStorage.setItem("FundDetailsV", "0");
    localStorage.setItem("FundScheduleV", "0");
    localStorage.setItem("PrintScheduleV", "0");
    commit("isnewfalse");
  },
  async checkAndUpdateData({ state, commit }, payload = {}) {
    const { updateLoadingText = false } = payload;
    try {
      // 假设使用 localStorage 存储版本号
      const localProjectVersion = parseInt(
        localStorage.getItem("projectV") || "0",
        10
      );
      const localProductVersion = parseInt(
        localStorage.getItem("productV") || "0",
        10
      );
      const localPartyBVersion = parseInt(
        localStorage.getItem("partyBV") || "0",
        10
      );
      const localContractVersion = parseInt(
        localStorage.getItem("contractV") || "0",
        10
      );
      const localContractChangeDetailsVersion = parseInt(
        localStorage.getItem("contractChangeDetailsV") || "0",
        10
      );
      const localProdValueDetailsVersion = parseInt(
        localStorage.getItem("prodValueDetailsV") || "0",
        10
      );
      const localBudgetDeductionDetailsVersion = parseInt(
        localStorage.getItem("BudgetDeductionDetailsV") || "0",
        10
      );
      const localFundDetailsVersion = parseInt(
        localStorage.getItem("FundDetailsV") || "0",
        10
      );
      const localFundScheduleVersion = parseInt(
        localStorage.getItem("FundScheduleV") || "0",
        10
      );
      const localPrintScheduleVersion = parseInt(
        localStorage.getItem("PrintScheduleV") || "0",
        10
      );
      //contractChangeDetailsV, prodValueDetailsV, BudgetDeductionDetailsV, FundDetailsV, FundScheduleV, PrintScheduleV,

      // 向后端发送请求
      if (updateLoadingText) {
        commit('updateLoadingText', '正在检查最新版本信息...');
      }
      const versionCheckResponse = await axios.post(
        `${apiConfig.baseURL}/api/check-version`,
        {
          projectV: localProjectVersion,
          productV: localProductVersion,
          partyBV: localPartyBVersion,
          contractV: localContractVersion,
          contractChangeDetailsV: localContractChangeDetailsVersion,
          prodValueDetailsV: localProdValueDetailsVersion,
          BudgetDeductionDetailsV: localBudgetDeductionDetailsVersion,
          FundDetailsV: localFundDetailsVersion,
          FundScheduleV: localFundScheduleVersion,
          PrintScheduleV: localPrintScheduleVersion,
        }
      );

      const { needUpdate, latestVersions } = versionCheckResponse.data;
      console.log("Version Check Response: ", versionCheckResponse.data);

      if (needUpdate.project) {
        // 获取最新的项目数据
        if (updateLoadingText) {
          commit('updateLoadingText', '正在加载 开发项目 数据...');
        }
        const projectsResponse = await axios.get(
          `${apiConfig.baseURL}/api/projects`,
          {
            params: { version: localProjectVersion },
          }
        );
        // 判断本地版本号，以决定是设置新数据还是更新现有数据
        if (localProjectVersion === 0) {
          commit("SET_DEVELOPMENT_PROJECTS", projectsResponse.data.projects);
        } else {
          commit("UPDATE_DEVELOPMENT_PROJECTS", projectsResponse.data.projects);
        }
        // 更新 sessionStorage 中的版本号
        localStorage.setItem("projectV", String(latestVersions.projectV));
      }
      if (needUpdate.product) {
        // 获取最新的产品数据
        if (updateLoadingText) {
          commit('updateLoadingText', '正在加载 开发产品 数据...');
        }
        const productsResponse = await axios.get(
          `${apiConfig.baseURL}/api/products`,
          {
            params: { version: localProductVersion },
          }
        );
        if (localProductVersion === 0) {
          commit("SET_DEVELOPMENT_PRODUCTS", productsResponse.data.products);
        } else {
          commit("UPDATE_DEVELOPMENT_PRODUCTS", productsResponse.data.products);
        }
        localStorage.setItem("productV", String(latestVersions.productV));
      }
      if (needUpdate.partyB) {
        if (updateLoadingText) {
          commit('updateLoadingText', '正在加载 乙方 数据...');
        }
        const partyBResponse = await axios.get(
          `${apiConfig.baseURL}/api/partyB`,
          {
            params: { version: localPartyBVersion },
          }
        );
        if (localPartyBVersion === 0) {
          commit("SET_PARTY_BS", partyBResponse.data.partyBData);
        } else {
          commit("UPDATE_PARTY_BS", partyBResponse.data.partyBData);
        }
        localStorage.setItem("partyBV", String(latestVersions.partyBV));
      }
      if (state.user.usertype) {
        if (needUpdate.contract) {
          if (updateLoadingText) {
            commit('updateLoadingText', '正在加载 合同 数据...')
          }
          const contractResponse = await axios.get(
            `${apiConfig.baseURL}/api/contract`,
            {
              params: { version: localContractVersion },
            }
          );
          if (localContractVersion === 0) {
            commit("SET_CONTRACTS", contractResponse.data.contractData);
          } else {
            commit("UPDATE_CONTRACTS", contractResponse.data.contractData);
          }
          if (contractResponse.data.contractData.length > 0) {
            localStorage.setItem("contractV", String(latestVersions.contractV));
          }

        }

        if (needUpdate.contractChangeDetails) {
          //在这里数据拉到dexie里
          if (updateLoadingText) {
            commit('updateLoadingText', '正在加载 合同明细表 数据...');
          }
          const contractChangeDetailsResponse = await axios.get(
            `${apiConfig.baseURL}/api/contractChangeDetails`,
            {
              params: { version: localContractChangeDetailsVersion },
            }
          );
          // 检查本地版本号，决定是设置新数据还是更新现有数据
          if (localContractChangeDetailsVersion === 0) {
            // 如果本地版本为 0，直接添加所有数据
            await db.contractChangeDetails.clear();
            await db.contractChangeDetails.bulkPut(
              contractChangeDetailsResponse.data.contractChangeDetailsData
            );
          } else {
            // 遍历后端返回的数据
            for (const newDetail of contractChangeDetailsResponse.data
              .contractChangeDetailsData) {
              const existingDetail = await db.contractChangeDetails.get(
                newDetail.rowID
              );
              if (existingDetail) {
                // 如果存在，更新该记录
                await db.contractChangeDetails.update(newDetail.rowID, newDetail);
              } else {
                // 如果不存在，添加新记录
                await db.contractChangeDetails.add(newDetail);
              }
            }
          }
          // 更新 sessionStorage 中的版本号
          if (contractChangeDetailsResponse.data.contractChangeDetailsData.length > 0) {
            localStorage.setItem(
              "contractChangeDetailsV",
              String(latestVersions.contractChangeDetailsV)
            );
          }
        }
          if (needUpdate.prodValueDetails) {
            if (updateLoadingText) {
              commit('updateLoadingText', '正在加载 产值明细表 数据...');
            }
            const prodValueDetailsResponse = await axios.get(
              `${apiConfig.baseURL}/api/prodValueDetails`,
              {
                params: { version: localProdValueDetailsVersion },
              }
            );
            if (localProdValueDetailsVersion === 0) {
              await db.prodValueDetails.clear();
              await db.prodValueDetails.bulkPut(
                prodValueDetailsResponse.data.prodValueDetailsData
              );
            } else {
              // 遍历后端返回的数据
              for (const newDetail of prodValueDetailsResponse.data
                .prodValueDetailsData) {
                const existingDetail = await db.prodValueDetails.get(
                  newDetail.rowID
                );
                if (existingDetail) {
                  // 如果存在，更新该记录
                  await db.prodValueDetails.update(newDetail.rowID, newDetail);
                } else {
                  // 如果不存在，添加新记录
                  await db.prodValueDetails.add(newDetail);
                }
              }
            }
            if (prodValueDetailsResponse.data.prodValueDetailsData.length > 0) {
            localStorage.setItem(
              "prodValueDetailsV",
              String(latestVersions.prodValueDetailsV)
            );
          }}
          //prodValueDetailsV, BudgetDeductionDetailsV, FundDetailsV, FundScheduleV, PrintScheduleV,
          if (needUpdate.BudgetDeductionDetails) {
            if (updateLoadingText) {
              commit('updateLoadingText', '正在加载 预算扣款明细表 数据...');
            }
            const BudgetDeductionDetailsResponse = await axios.get(
              `${apiConfig.baseURL}/api/BudgetDeductionDetails`,
              {
                params: { version: localBudgetDeductionDetailsVersion },
              }
            );

            if (localBudgetDeductionDetailsVersion === 0) {
              await db.BudgetDeductionDetails.clear();
              await db.BudgetDeductionDetails.bulkPut(
                BudgetDeductionDetailsResponse.data.BudgetDeductionDetailsData
              );
            } else {
              for (const newDetail of BudgetDeductionDetailsResponse.data
                .BudgetDeductionDetailsData) {
                const existingDetail = await db.BudgetDeductionDetails.get(
                  newDetail.rowID
                );
                if (existingDetail) {
                  await db.BudgetDeductionDetails.update(
                    newDetail.rowID,
                    newDetail
                  );
                } else {
                  await db.BudgetDeductionDetails.add(newDetail);
                }
              }
            }
            if (BudgetDeductionDetailsResponse.data.BudgetDeductionDetailsData.length > 0) {
            localStorage.setItem(
              "BudgetDeductionDetailsV",
              String(latestVersions.BudgetDeductionDetailsV)
            );
          }}
          if (needUpdate.FundDetails) {
            if (updateLoadingText) {
              commit('updateLoadingText', '正在加载 付款明细表 数据...');
            }
            const FundDetailsResponse = await axios.get(
              `${apiConfig.baseURL}/api/FundDetails`,
              {
                params: { version: localFundDetailsVersion },
              }
            );

            if (localFundDetailsVersion === 0) {
              await db.FundDetails.clear();
              await db.FundDetails.bulkPut(
                FundDetailsResponse.data.FundDetailsData
              );
            } else {
              for (const newDetail of FundDetailsResponse.data.FundDetailsData) {
                const existingDetail = await db.FundDetails.get(newDetail.rowID);
                if (existingDetail) {
                  await db.FundDetails.update(newDetail.rowID, newDetail);
                } else {
                  await db.FundDetails.add(newDetail);
                }
              }
            }
            console.log('FundDetailsResponse:', FundDetailsResponse.data.FundDetailsData)
            if (FundDetailsResponse.data.FundDetailsData.length > 0) {
              localStorage.setItem(
                "FundDetailsV",
                String(latestVersions.FundDetailsV)
              );
            }
          }
          if (needUpdate.FundSchedule) {
            if (updateLoadingText) {
              commit('updateLoadingText', '正在加载 日期可调资金计划表 数据...');
            }
            const FundScheduleResponse = await axios.get(
              `${apiConfig.baseURL}/api/FundSchedule`,
              {
                params: { version: localFundScheduleVersion },
              }
            );

            if (localFundScheduleVersion === 0) {
              await db.FundSchedule.clear();
              await db.FundSchedule.bulkPut(
                FundScheduleResponse.data.FundScheduleData
              );
            } else {
              for (const newDetail of FundScheduleResponse.data.FundScheduleData) {
                const existingDetail = await db.FundSchedule.get(newDetail.rowID);
                if (existingDetail) {
                  await db.FundSchedule.update(newDetail.rowID, newDetail);
                } else {
                  await db.FundSchedule.add(newDetail);
                }
              }
            }
            if (FundScheduleResponse.data.FundScheduleData.length > 0) {
            localStorage.setItem(
              "FundScheduleV",
              String(latestVersions.FundScheduleV)
            );
          }}

          if (needUpdate.PrintSchedule) {
            if (updateLoadingText) {
              commit('updateLoadingText', '正在加载 打印明细表 数据...');
            }
            const PrintScheduleResponse = await axios.get(
              `${apiConfig.baseURL}/api/PrintSchedule`,
              {
                params: { version: localPrintScheduleVersion },
              }
            );

            if (localPrintScheduleVersion === 0) {
              await db.PrintSchedule.clear();
              await db.PrintSchedule.bulkPut(
                PrintScheduleResponse.data.PrintScheduleData
              );
            } else {
              for (const newDetail of PrintScheduleResponse.data
                .PrintScheduleData) {
                const existingDetail = await db.PrintSchedule.get(newDetail.rowID);
                if (existingDetail) {
                  await db.PrintSchedule.update(newDetail.rowID, newDetail);
                } else {
                  await db.PrintSchedule.add(newDetail);
                }
              }
            }
            if (PrintScheduleResponse.data.PrintScheduleData.length > 0) {
            localStorage.setItem(
              "PrintScheduleV",
              String(latestVersions.PrintScheduleV)
            );
          }}
        }
        commit("isnewtrue");
        if (updateLoadingText) {
          commit('updateLoadingText', 'null');
        }
      } catch (error) {
        console.error("Error in checkAndUpdateData:", error);
      }
    },
    // 其他 actions 可以在这里添加
  };
