// src/store/getters.js

export default {
  projectOptions(state) {
    return state.developmentProjects.map((project) => ({
      label: project.projectNAME,
      value: project.projectNAME,
      id: project.projectID,
      projectV : project.projectV,
    }));
  },
  productOptions(state) {
    return state.developmentProducts.map((product) => ({
      product: product.product,
      id: product.productID,
      project: product.project,
      build: product.ifBuilding,
      productV : product.productV,
    }));
  },
  partyBOptions(state) {
    return state.partyBs.map((partyB) => ({
      label: partyB.partyBNAME,
      value: partyB.partyBNAME,
      remarks: partyB.remarks,
      id: partyB.partyBID,
      partyBV : partyB.partyBV,
    }));
  },
  contractOptions(state){
    return state.contracts.map(contract =>({
      label: contract.contractName,
      id: contract.contractID,
      partyA: contract.partyA,
      project: contract.project,
      product: contract.product,
      partyB: contract.partyB,
      affiliateUnit: contract.affiliateUnit,
      contractType: contract.contractType,
      contractStatus: contract.contractStatus,
      belongingBuilding: contract.belongingBuilding,
      remarks: contract.remarks,
      url: contract.links,
      permissionsA: contract.permissionsA,
      permissionsB: contract.permissionsB,
      contractV : contract.contractV,
    }));
  },
  getProjectVById: (state) => (id) => {
    const project = state.developmentProjects.find(project => project.projectID === id);
    return project ? project.projectV : null;
  },
  getProductVById: (state) => (id) => {
    const product = state.developmentProducts.find(product => product.productID === id);
    return product ? product.productV : null;
  },
  getPartyBVById: (state) => (id) => {
    const partyB = state.partyBs.find(partyB => partyB.partyBID === id);
    return partyB ? partyB.partyBV : null;
  },
  getContractVById: (state) => (id) => {
    const contract = state.contracts.find(contract => contract.contractID === id);
    return contract ? contract.contractV : null;
  },
  // 其他 getters 可以在这里添加
};
