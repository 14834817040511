// src/store/state.js

export default {
  developmentProjects: [],
  developmentProducts: [],
  partyBs:[],
  contracts:[],
  user: {
    username: null,
    usertype: null,
    token: null,
    company:null,
  },
  history: [],
  isUndoing: false, // 用于控制是否应该保存筛选历史的标志
  isworking: true,
  pageState: {
    activeTab: 'sheet0',  // 默认激活的 Tab
    currentrow: 1,           // 默认的当前页面
  },
  dataCompany:[],
  isnew : false ,
  loadingText: 'null',
};
