// src/store/index.js

import { createStore } from "vuex";
import state from "./state.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";
import createPersistedState from "vuex-along"; // 导入 vuex-along

const store = createStore({
  state,
  getters,
  mutations,
  actions,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ], // 添加 vuex-along 作为插件
});

export default store;
