// src/store/mutations.js

export default {
  updateLoadingText(state, text) {
    state.loadingText = text;
  },
  SET_DEVELOPMENT_PROJECTS(state, projects) {
    state.developmentProjects = projects;
  },
  UPDATE_DEVELOPMENT_PROJECTS(state, newProjects) {
    const projectsMap = new Map(
      state.developmentProjects.map((project) => [project.projectID, project])
    );
    newProjects.forEach((newProject) => {
      if (projectsMap.has(newProject.projectID)) {
        Object.assign(projectsMap.get(newProject.projectID), newProject);
      } else {
        projectsMap.set(newProject.projectID, newProject);
      }
    });
    state.developmentProjects = Array.from(projectsMap.values());
  },

  SET_DEVELOPMENT_PRODUCTS(state, products) {
    state.developmentProducts = products;
  },
  UPDATE_DEVELOPMENT_PRODUCTS(state, newProducts) {
    const productsMap = new Map(
      state.developmentProducts.map((product) => [product.productID, product])
    );
    newProducts.forEach((newProduct) => {
      if (productsMap.has(newProduct.productID)) {
        Object.assign(productsMap.get(newProduct.productID), newProduct);
      } else {
        productsMap.set(newProduct.productID, newProduct);
      }
    });
    state.developmentProducts = Array.from(productsMap.values());
  },

  SET_PARTY_BS(state, partyBData) {
    state.partyBs = partyBData;
  },
  UPDATE_PARTY_BS(state, newPartyBData) {
    const partyBMap = new Map(
      state.partyBs.map((partyB) => [partyB.partyBID, partyB])
    );
    newPartyBData.forEach((newPartyB) => {
      if (partyBMap.has(newPartyB.partyBID)) {
        Object.assign(partyBMap.get(newPartyB.partyBID), newPartyB);
      } else {
        partyBMap.set(newPartyB.partyBID, newPartyB);
      }
    });
    state.partyBs = Array.from(partyBMap.values());
  },

  SET_CONTRACTS(state, contractData) {
    state.contracts = contractData;
  },
  UPDATE_CONTRACTS(state, newContractData) {
    const contractMap = new Map(
      state.contracts.map((contract) => [contract.contractID, contract])
    );
    newContractData.forEach((newContract) => {
      if (contractMap.has(newContract.contractID)) {
        Object.assign(contractMap.get(newContract.contractID), newContract);
      } else {
        contractMap.set(newContract.contractID, newContract);
      }
    });
    state.contracts = Array.from(contractMap.values());
  },

  ADD_DEVELOPMENT_PROJECT(state, project) {
    state.developmentProjects.push(project);
  },
  REMOVE_DEVELOPMENT_PROJECT(state, projectId) {
    state.developmentProjects = state.developmentProjects.filter(
      (project) => project.id !== projectId
    );
  },
  EDIT_DEVELOPMENT_PROJECT(state, updatedProject) {
    const index = state.developmentProjects.findIndex(
      (project) => project.id === updatedProject.id
    );
    if (index !== -1) {
      state.developmentProjects[index] = updatedProject;
    }
  },
  // 设置用户信息的动作
  SET_USER(state, { username, usertype, token, department }) {
    state.user.username = username;
    state.user.usertype = usertype;
    state.user.token = token;
    state.user.company = department;
  },
   // 设置页面状态
   SET_PAGE_STATE(state, { activeTab, currentrow }) {
    state.pageState.activeTab = activeTab;
    state.pageState.currentrow = currentrow;
  },
  // 重置页面状态为默认值
  RESET_PAGE_STATE(state) {
    state.pageState.activeTab = 'sheet0';
    state.pageState.currentrow = 1;
  },
  //设置当前甲方
  SET_COMPANY(state, company ) {
    state.dataCompany = company;
  },
  // 其他 mutations 可以在这里添加
  addHistory(state, { filtermore, filter }) {
    if (!state.isUndoing) {
      state.history.push({ filtermore, filter });
    }
  },
  setIsUndoing(state, status) {
    state.isUndoing = status;
  },
  undo(state) {
    if (state.history.length > 0) {
      state.isUndoing = true;
      state.history.pop();
    }
  },
  clearHistory(state) {
    state.history = [];
  },
  setworking(state) {
    state.isworking = !state.isworking;
  },
  resetworking(state) {
    state.isworking = false;
  },
  isnewtrue(state) {
    state.isnew = true;
  },
  isnewfalse(state) {
    state.isnew = false;
  }
};
